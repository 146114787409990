import { defineStore } from 'pinia'
import { useCountry } from '@/composables/country/useCountry'

import { DynadminCMSStore, RegionalSettingInterface } from '@/types/cms/admin'
import { RegionalSettings } from '~/modules/contentstack/types'

export const useStore = defineStore({
  id: 'cms-dynadmin',
  state: (): DynadminCMSStore => ({
    regionalSettings: {},
    loading: false,
    allRegionalSettings: {},
  }),
  actions: {
    async fetchRegionalSettings(devkeys: string[], country: string | null = null) {
      try {
        this.loading = true
        const { getLocaleCode, selectedCountry } = useCountry()

        // If no country is provided, use the selected country
        const { data } = await this.$nuxt.$cms.api.fetchRegionalSettings({
          countryCode: !country ? selectedCountry.value : country,
          devkeys,
          locale: getLocaleCode(),
          fallbackLocale: true,
        })

        // If no items, return
        if (!data?.all_regional_settings?.items) {
          // TODO: Send error to logservice about missing regional settings for this country
          return
        }

        // Group everything on devkey
        const regionalSettingsArray = devkeys.map((key) => {
          return data?.all_regional_settings?.items?.filter((item: any) => item.devkey === key)
        }) as RegionalSettingInterface[][]

        // Set in store
        regionalSettingsArray.forEach((regionalSettingsArrayItem) => {
          // check if we have a regional setting for this devkey
          // Find the default_setting false first, when not present take the default version
          const regionalSetting =
            regionalSettingsArrayItem?.find((item) => !item?.is_default_setting) ??
            (regionalSettingsArrayItem?.find((item) => item?.is_default_setting) as RegionalSettingInterface)

          if (regionalSetting) {
            this.$patch({
              regionalSettings: { [regionalSetting.devkey as keyof typeof regionalSetting]: regionalSetting },
            })
            this.loading = false
          }
        })
      } catch (error: any) {
        console.error(error)
      }
    },
    async fetchAllRegionalSettings(devkeys: string[]) {
      const { getLocaleCode } = useCountry()
      try {
        const { data } = await this.$nuxt.$cms.api.fetchAllRegionalSettings({
          devkeys,
          locale: getLocaleCode(),
          fallbackLocale: true,
        })
        // If no items, return
        if (!data?.all_regional_settings?.items) {
          // TODO: Send error to logservice about missing regional settings for this country
          return
        }
        devkeys.forEach((devkey) => {
          const filteredRegionalSettings = data.all_regional_settings?.items?.filter((item) => item?.devkey === devkey)
          this.$patch({
            allRegionalSettings: { [devkey]: filteredRegionalSettings as RegionalSettings[] },
          })
        })
      } catch (error: any) {
        console.error(error)
      }
    },
    clearRegionalSettings() {
      this.$reset()
    },
  },
  getters: {
    getRegionalSetting: (state) => {
      return (devkey: string) => state.regionalSettings[devkey as keyof typeof state.regionalSettings]
    },
    getAllRegionalSettingsByDevkey: (state) => {
      return (devkey: string) => state.allRegionalSettings[devkey as keyof typeof state.allRegionalSettings]
    },
  },
})
