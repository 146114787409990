import { RegionalSettings } from '~/modules/contentstack/types'
import { AudienceConnection } from '~/store/cms/global-settings'

export interface SettingParam {
  audienceConnection: AudienceConnection
  parsed: any
}

/**
 * Will return global setting instance by country code. Country code parameter is optional and when not specified, fallback global setting will be returned.
 * Fallback global setting is for US/ROW and has neither country nor audience defined.
 * @param settings - Array of Global Settings items
 * @param countryCode(optional) - String of current country code
 */
export function getSettingByCountryCode<T extends SettingParam | RegionalSettings>(
  settings: T[],
  countryCode?: string
): T | undefined {
  // Fallback default (US) Setting is when the country is not set in the CMS
  let setting = settings?.find((item) => !item.audienceConnection?.edges?.length)

  if (!countryCode) {
    return setting
  }

  setting =
    settings?.find((item) => {
      const edges = item.audienceConnection?.edges
      const firstEdge = edges?.[0]?.node

      return (
        (firstEdge?.__typename === 'Country' && firstEdge.country_code === countryCode) ||
        (firstEdge?.__typename === 'Audience' &&
          firstEdge.countriesConnection?.edges?.some((audience) => audience?.node?.country_code === countryCode))
      )
    }) ?? setting

  return setting
}
